<template>
  <div class="layout-main bg-white">
    <div class="journey detail" v-if="journeyData">
      <div class="header journey-block position-relative">
        <div class="fs-h2 text-md-center" v-text="journeyData.name"></div>
        <div class="option-btn-group d-flex justify-content-md-center">
          <button class="btn common-oval-btn dark fs-capation-12" @click="downloadGPXClicked">GPX</button>
          <button class="btn common-oval-btn dark fs-capation-12">軌跡圖</button>
        </div>
        <b-dropdown class="more-option" variant="link" right no-caret>
          <template #button-content>
            <Icon name="more_horiz" size="24" />
          </template>
          <b-dropdown-item-button button-class="d-flex justify-content-between align-items-center" @click="journeyEdit">
            <span>編輯行程</span>
            <Icon name="edit" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button button-class="d-flex justify-content-between align-items-center" @click="copyLinkClicked">
            <span>分享行程</span>
            <Icon name="share" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button button-class="d-flex justify-content-between align-items-center" @click="deleteBtnClicked">
            <span>刪除行程</span>
            <Icon name="volume" size="24" />
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div class="journey-content journey-block">
        <div class="block-title fs-h4">計畫內容</div>
        <div class="operating-list">
          <div class="operating-item">
            <div class="name">
              <div class="fs-body-16">出發日期</div>
            </div>
            <div class="info">
              <div class="fs-title-18">{{ moment(journeyData.journey[0].startTimestamp).format('YYYY/MM/DD (dd) HH:mm') }}</div>
            </div>
          </div>
          <div class="operating-item">
            <div class="name">
              <div class="fs-body-16">下山時間</div>
            </div>
            <div class="info">
              <div class="fs-title-18">{{ moment(journeyData.journey[journeyData.journey.length - 1].endTimestamp).format('YYYY/MM/DD (dd) HH:mm') }}</div>
            </div>
          </div>
          <div class="operating-item">
            <div class="name">
              <div class="fs-body-16">行程天數</div>
            </div>
            <div class="info">
              <div class="fs-title-18" v-text="durationDays"></div>
            </div>
          </div>
          <router-link :to="{name: 'JourneyDetailSubmit'}" class="operating-item">
            <div class="name">
              <div class="fs-body-16">申請資訊</div>
            </div>
            <div class="info">
              <span class="fs-title-18" v-text="startRegMsg"></span>
              <span class="fs-body-18" v-if="passRule.areaHouseRule"> [預先登記後抽籤]</span>
            </div>
          </router-link>
          <router-link :to="{name: 'JourneyDetailMember'}" class="operating-item">
            <div class="name">
              <div class="fs-body-16">參與人員</div>
            </div>
            <div class="info">
              <div class="fs-title-18" v-text="participantCount"></div>
            </div>
          </router-link>
<!--          <div class="operating-item">-->
<!--            <div class="name">-->
<!--              <div class="fs-body-16">接駁服務</div>-->
<!--            </div>-->
<!--            <div class="info">-->
<!--              <div class="fs-title-18">尚未預訂接駁</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="journey-plan journey-block">
        <div class="block-title fs-h4">路線規劃</div>
        <div class="content-list">
          <div class="content-item d-flex justify-content-between">
            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="directions_walk" size="20" />
                <div class="info-text fs-body-16">行走</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="formatTimeToHoursAndMinutes(journeyData.pathInfo.totalWalkTime)"></span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="distance" size="20" />
                <div class="info-text fs-body-16">距離</div>
              </div>
              <div class="info-value fs-h2">
                <span>{{ Math.round(journeyData.pathInfo.totalDistance / 1000 * 10) / 10 }}</span>
                <span class="unit">km</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-up" size="20" />
                <div class="info-text fs-body-16">總升</div>
              </div>
              <div class="info-value fs-h2">
                <span>{{ journeyData.pathInfo.totalUp }}</span>
                <span class="unit">m</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-down" size="20" />
                <div class="info-text fs-body-16">總降</div>
              </div>
              <div class="info-value fs-h2">
                <span>{{ journeyData.pathInfo.totalDown }}</span>
                <span class="unit">m</span>
              </div>
            </div>
          </div>
          <div class="content-item">
            
            <div class="name">
              <div class="fs-body-16">行走速率</div>
            </div>
            <div class="info">
              <div class="fs-title-18">x 1 (標準)</div>
            </div>
          </div>
          <div class="content-item">
            <div class="journey-info row">
              <div class="col-12 col-md-6">
                <div class="box trail-map" :class="{ 'scale-active': mapScale }">
                  <img class="thumbnail d-md-none" :src="[journeyData.picUrl]" alt="路線縮圖">
                  <button class="btn scale d-md-none" @click="mapScaleToggle">
                    <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                  </button>
                  <div class="path-map-group custom-z-index common-transition">
                    <path-map ref="map" v-if="shownPathFullPosition.length > 0" :path="shownPathFullPosition" :milestones="shownPathMilestones"></path-map>
                    <button class="btn scale d-md-none" @click="mapScaleToggle">
                      <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box overflow-auto">
                  <journey-viewer :journey="journeyData" :show-opt-btn="false" :show-weather="false" :show-start-time-select="false"></journey-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import JourneyViewer from "@/components/common/JourneyViewer.vue";
import PathMap from "@/components/common/PathMap.vue";

export default {
  name: 'JourneyDetail',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      journeyData: null,
      shownPathMilestones: [],
      shownPathFullPosition: [],
      shownPathFullHeights: [],
      joinedOwner: {},
      joinedMembers: [],
      passRule: {},
      startRegMsg: '',
      tempId: -1,

      mapScale: false,
    };
  },
  props: {
  },
  components: {
    PathMap,
    JourneyViewer,
  },
  computed: {
    moment() {
      return moment
    },
    durationDays() {
      const dayMappings = {
        1: '單攻',
        2: '兩天',
      };

      for (let i = 3; i <= 9; i++) {
        dayMappings[i] = `${this.toChineseNumber(i)}天`;
      }

      if (this.journeyData && this.journeyData.durationDays in dayMappings) {
        return dayMappings[this.journeyData.durationDays];
      } else {
        return '資料獲取失敗';
      }
    },
    participantCount() {
      if (this.joinedMembers && this.joinedMembers.length > 0) {
        return this.joinedMembers.length + 1;
      } else {
        return 1;
      }
    }
	},
  watch: {
    
  },
  async mounted() {
    const journeyId = this.$route.params.id;
    await this.readInfo(journeyId);
    this.__calcStartRegMsg();
	},
  methods: {
    async readInfo(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', journeyId);
        this.buildFullPathAndHeights(this.journeyData.journey);
        this.buildPathMilestones(this.journeyData.journey);
        // const dayTemplatesRequest = await this.$store.dispatch('api/getPathDayTemplatePromise', this.journeyData.baseOnPathSerial);
        // const targetTemp = dayTemplatesRequest.find(item => item.days === this.journeyData.durationDays);
        // this.tempId = targetTemp.serial;
        const joinedData = await this.$store.dispatch('api/getJourneyJoinSubmitListPromise', journeyId)
        if (joinedData !== null) {
          this.joinedOwner = Object.assign({}, this.joinedOwner, joinedData.owner);
          joinedData.list.map((item) => {
            if (item.user.serial === this.user.serial) {
              this.joinedMembers.unshift(item);
            } else {
              this.joinedMembers.push(item);
            }
          })
        }
        
        this.passRule = await this.$store.dispatch('api/getJourneyPassRulePromise', journeyId)
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    formatTimeToHoursAndMinutes(value) {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    },
    buildFullPathAndHeights(journeyItems) {
      // const _this = this;
      this.shownPathFullPosition.splice(0, this.shownPathFullPosition.length);
      this.shownPathFullHeights.splice(0, this.shownPathFullHeights.length);

      journeyItems.forEach((item) => {
        if (item.type === 'milestone') {
          this.shownPathFullPosition.push({
            lat: item.detail.lat,
            lng: item.detail.lng,
          });
        } else if (item.type === 'route') {
          const detail = item.detail;
          const routepts = detail.routepts;
          const heights = detail.heights;

          if (item.direction === 0) {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights);
          } else {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.reverse().map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights.reverse());
          }
        }
      });
    },
    buildPathMilestones(journeyItems) {
      this.shownPathMilestones.splice(0, this.shownPathMilestones.length);

      for (const item of journeyItems) {
        if (item.type === 'milestone') {
          const found = this.shownPathMilestones.filter((m) => {
            return m.serial === item.detail.serial;
          });
          if (found.length === 0) {
            this.shownPathMilestones.push(item.detail);
          }
        }
      }
    },
    __calcStartRegMsg() {
      let d = moment(this.journeyData.startDate);
      console.log(d)
      if (this.passRule.areaHouseRule !== null) {
        this.startRegMsg = d.format('YYYY/MM/DD') + ' 開始申請' + this.passRule.areaHouseRule.houseName
      } else {
        this.startRegMsg = d.format('YYYY/MM/DD') + ' 開始申請'
      }
    },
    toChineseNumber(number) {
      const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      const units = ['', '十', '百', '千', '萬', '億', '兆'];
      const numberStr = number.toString();
      let result = '';
      for (let i = 0; i < numberStr.length; i++) {
        const digit = parseInt(numberStr[i]);
        if (digit !== 0) {
          result += chineseNumbers[digit] + units[numberStr.length - i - 1];
        } else {
          if (i === numberStr.length - 1 || parseInt(numberStr[i + 1]) !== 0) {
            result += chineseNumbers[digit];
          }
        }
      }
      return result;
    },
    mapScaleToggle: function () {
      this.mapScale = !this.mapScale;
    },
    downloadGPXClicked: function() {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const journeyId = this.journeyData._id;
      const journeyGPXLink = this.$store.state.api.URLS.GETJOURNEYGPX;
      const downloadUrl = baseUrl + journeyGPXLink + '/' + journeyId;
      // console.log(downloadUrl);
      window.open(downloadUrl, '_blank');
    },
    journeyEdit: async function () {
      await this.$router.push({ name: 'JourneyDetailEdit', params: { id: this.$route.params.id } });
    },
    deleteBtnClicked: async function() {
      let r = confirm('確定刪除【' + this.journeyData.name + '】?');
      if (r) {
        try {
          await this.$store.dispatch('api/postJourneyDeletePromise', this.journeyData._id);
          alert('成功刪除【' + this.journeyData.name + '】');
          await this.$router.push({ name: 'MyPlan' });
        } catch (error) {
          await this.$store.dispatch('appendErrorMsg', error.toString());
        }
      }
    },
    copyLinkClicked: function() {
      window.copyMsg(window.location.href);
      alert('已複製分享連結!');
    },
  }
}
</script>

<style lang="scss">
  @import "src/assets/scss/journey";
</style>
