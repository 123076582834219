<template>
  <div class="journey-viewer">
    <div v-if="inited">
      <div v-for="(item, index) in journeyDay" :key="index" class="journey-single-day">
        <div class="journey-day-header">
          <div class="day-title">
            <p>Day</p>
            <p class="big-text">{{index + 1}}</p>
            <p v-if="index === 0">{{__dayStr(index)}}</p>

            <p class="weather-msg" v-text="__weatherMsg(index)"></p>
          </div>
          <hr/>
        </div>
        <journey-day :climb-unit="climbUnit" :journey-item="item" :show-header-info="true" :show-start-time-select="showStartTimeSelect" :show-timestamp="true" :show-opt-btn="showOptBtn" :show-weather="showWeather && (__weatherType(index) === 0)" @optclick="__milestoneOptClicked(index, $event)" @start-time-changed="__milestoneStartTimeChanged(index, $event)"></journey-day>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import JourneyDay from './JourneyDay.vue';

export default {
  name: 'JourneyViewer',
  components: {
    JourneyDay
  },
  data() {
    return {
      journeyDay: [],
      inited: false,
    }
  },
  props: {
    journey: {
      type: Object,
      required: true,
    },
    climbUnit: {
      type: Number,
      required: false,
      default: 1,
    },
    showStartTimeSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    showOptBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    showWeather: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  created() {
    // 避免prop尚未進入導致的undefined, __refresh移至watch中執行
  },
  async mounted() {
    if(this.journey) {
      await this.__refresh();
      this.inited = true;
    }
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    journey: {
      deep: true, // 監測對象内部屬性的變化
      handler(newPropValue) {
        // 監聽prop的變化
        if (newPropValue !== undefined) {
          this.__refresh();
        }
      },
    },
  },
  methods: {
    __milestoneOptClicked(index, subIndex) {
      let realIndex = 0;
      if (index > 0) {
        realIndex = this.journey.journeySplitIndexs[index - 1];
      }
      realIndex += subIndex;
      // console.log(`${realIndex} clicked.`);
      this.$emit('optclick', realIndex);
    },
    __milestoneStartTimeChanged(index, startTime) {
      this.$emit('start-time-changed', {
        index,
        startTime,
      });
    },
    __splitJourney() {
      this.journeyDay.splice(0, this.journeyDay.length);
      if (this.journey.journeySplitIndexs.length === 0) {
        this.journeyDay.push(this.journey.journey);
      } else {
        let temp = [];
        let ind = 0;
        for (let i=0;i<this.journey.journey.length;i++) {
          if (i === this.journey.journeySplitIndexs[ind]) {
            const tempEnd = {... this.journey.journey[i]};
            tempEnd.endTimestamp = tempEnd.startTimestamp;
            tempEnd.endTime = moment(tempEnd.endTimestamp).format('YYYY-MM-DD HH:mm');
            temp.push(tempEnd);

            ind += 1;
            this.journeyDay.push(temp);
            temp = [];

            const tempStart = {... this.journey.journey[i]};
            tempStart.startTimestamp = tempStart.endTimestamp;
            tempStart.startTime = moment(tempStart.startTimestamp).format('YYYY-MM-DD HH:mm');
            temp.push(tempStart);
          } else {
            temp.push(this.journey.journey[i]);
          }
        }
        this.journeyDay.push(temp);
      }
    },
    __dayStr(index) {
      const d = moment(this.journey.startDate);
      // console.log(moment.months());
      d.add(index, 'days');
      return d.format('MM/DD(dd)');
    },
    __weatherType(index) {
      const d = moment(this.journey.startDate);
      d.hour(0).minute(0).second(0).millisecond(0);
      d.add(index, 'days');
      const m = moment().hour(0).minute(0).second(0).millisecond(0);
      if (d.isBefore(m)) {
        return -1;
      }
      m.add(7, 'days');
      if (d.isBefore(m)) {
        return 0;
      }
      return 1;
    },
    __weatherMsg() {
    // __weatherMsg(index) {
      // 2023/05/03 氣候來源不準，暫時關閉
      // const type = this.__weatherType(index);
      // if (type === -1) {
      //   return '*已無氣候資訊';
      // } else if (type === 1) {
      //   const d = moment(this.journey.startDate);
      //   d.add(index, 'days');
      //   d.subtract(6, 'days');
      //   return `*將於${d.format('MM/DD')}顯示天氣預報`;
      // }
      return '';
    },
    __refresh() {
      try {
        this.__splitJourney();
      } catch(e) {
        console.error(e);
      }
    }
  }

}
</script>

<style scoped>
  ::v-deep .journey-single-day:first-child .journey-row:first-child a.milestone-opt, ::v-deep .journey-single-day:last-child .journey-row:last-child a.milestone-opt {
    display: none;
  }

  p {
    margin: 0;
  }

  .journey-viewer {

  }

  .journey-single-day {
    padding: 1rem 0;
    margin-bottom: 2rem;
    background-color: #fff;
  }

  .journey-day-header {
    /* margin-bottom: .5rem; */
  }

  .journey-day-header hr {
    margin: .5rem 0;
    border-top: solid 1px #000;
  }

  .day-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .day-title p {
    padding-right: 1rem;
    font-size: .75em;
  }

  .day-title p.big-text {
    font-size: 1.2em;
    font-weight: 1000;
  }

  .day-title p.weather-msg {
    flex: 1 1;
    text-align: right;
    font-size: .6em;
    color: #0005;
    padding-right: 0;

  }

  .day-feature>p {
    font-size: .7rem;
  }

  .day-feature .big-text {
    font-size: 1.1rem;
    font-weight: 1000;
  }

  .day-start-time-select>select {
    margin-left: 1rem;
    border: none;
  }

  @media (min-width: 992px) {
    .journey-single-day {
      padding: 1rem 0;
    }

    .journey-day-header {
      padding: 0 2rem;
    }
  }
</style>
